import React, { useEffect, useMemo, useState } from 'react';
import { formatNegativeNumFromStr, formatNum, formatNumOneDecimal } from '../../utils/formatNum';

interface IGrandTotal {
  scope: 'Management' | 'Regional';
  // had to define row prop-type to render cells in each column
  grandTotalData: any;
  title: string;
}

export const GrandTotal: React.FC<IGrandTotal> = ({ grandTotalData, title, scope }) => {
  if (!grandTotalData) return null;

  return (
    <tr>
      <td>
        <strong>{title}</strong>
      </td>
      {scope === 'Regional' && (
        <td>
          <strong>{formatNum(grandTotalData?.execTotalQuantity, 1, 1)}</strong>
        </td>
      )}
      <td className="text-center">
        <strong>
          {formatNum(
            scope === 'Management' ? grandTotalData?.execTotalSalesQuantity : grandTotalData?.notExecTotalSalesQuantity,
            1,
            1,
          )}
        </strong>
        <br />
        <strong>{formatNum(grandTotalData.priorYearSalesGrowth, 1, 1)}%</strong>
      </td>
      <td className="text-center">
        <strong>{formatNum(grandTotalData?.execYear1T12, 1, 1)}</strong>
        <br />
        <strong>{formatNum(grandTotalData?.year1T12Growth, 1, 1)}%</strong>
      </td>
      <td className="text-center">
        <strong>{formatNum(grandTotalData?.execYear1YTD, 1, 1)}</strong>
        <br />
        <strong>{formatNum(grandTotalData?.year1YTDGrowth, 1, 1)}%</strong>
      </td>
      <td>
        <strong className={scope === 'Regional' ? 'text-dark' : ''}>
          {formatNum(
            scope === 'Management' ? grandTotalData?.execTotalQuantity : grandTotalData?.notExecTotalQuantity,
            1,
            1,
          )}
        </strong>
      </td>
      <td>
        <strong className={scope === 'Regional' ? 'text-dark' : ''}>
          {formatNum(
            scope === 'Management' ? grandTotalData?.execTotalSalesGrowth : grandTotalData?.notExecTotalSalesGrowth,
            1,
            1,
          )}
          %
        </strong>
      </td>
      <td>
        <strong></strong>
      </td>
      <td>
        <strong>
          $
          {formatNum(
            scope === 'Management' ? grandTotalData?.execTotalSalesRevenue : grandTotalData?.notExecTotalSalesRevenue,
          )}
        </strong>
      </td>
      <td>
        <strong>
          ${formatNum(scope === 'Management' ? grandTotalData?.execTotalRevenue : grandTotalData?.notExecTotalRevenue)}
        </strong>
      </td>
      <td>
        <strong>
          {formatNum(
            scope === 'Management' ? grandTotalData?.execTotalRevenueGrowth : grandTotalData?.notExecTotalRevenueGrowth,
          )}
          %
        </strong>
      </td>
    </tr>
  );
};

export default GrandTotal;
